<template>
  <div :class="{ 'is-loading': isLoading }" class="Collection canLoad">
    <!--<template v-if="filteredItems.length">-->
    <transition-group
      name="list"
      tag="div"
      class="columns is-multiline"
    >
      <div
        v-if="$scopedSlots.firstItem"
        key="firstItem"
        :class="[width, firstItemClass]"
        class="Collection-Item Collection-Item--first"
      >
        <slot :items="filteredItems" :hydrated="hydrated" name="firstItem" />
      </div>
      <slot
        v-if="!filteredItems.length"
        name="noItems"
      />
      <div
        v-for="(item, index) in filteredItems"
        :key="item.id"
        :class="[width, itemClassFunc(item,index)]"
        class="Collection-Item"
      >
        <slot :item="item" name="item">
          <p class="Collection__item">
            {{ item }}
          </p>
        </slot>
      </div>
    </transition-group>
    <!--</template>-->
    <div class="Collection__load-more has-text-centered">
      <transition name="fade" mode="out-in">
        <div v-if="!hydrated" key="fetchingText">
          {{ fetchingText }}
        </div>

        <div v-else key="loadMoreButton">
          <button
            v-if="hasMore"
            :class="{
              'is-loading': isLoading,
              'is-primary': hasPrimaryButton
            }"
            :disabled="isLoading"
            class="button"
            data-testid="LoadMore_Button"
            @click="load"
          >
            {{ $t('general.load_more') }}
          </button>

          <div
            v-if="!list.length || !hasMore"
            key="noitems"
            class="has-text-muted is-size-7 is-uppercase"
          >
            {{ noMoreText }}
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoadMore',

  props: {
    endpoint: { type: String, required: true },
    width: { type: String, default: 'column' },
    filter: { type: Function, default: (items) => items },
    hasPrimaryButton: { type: Boolean, default: true },
    noMoreText: { type: String, default () { return this.$t('general.no_more_items') } },
    fetchingText: { type: String, default () { return this.$t('general.fetching_items') } },
    itemClassFunc: {
      type: Function, default: () => item => ''
    },
    firstItemClass: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      list: [],
      hasMore: false,
      cursor: null,
      isLoading: false,
      hydrated: false
    }
  },

  computed: {
    cursorQuery () {
      let cursorParams = {}

      this.cursor && (cursorParams.cursor = this.cursor)

      return cursorParams
    },

    filteredItems: {
      get () {
        return this.filter(this.list)
      },
      set (items) {
        this.list = items
      }
    }
  },

  watch: {
    endpoint () {
      this.list = []
      this.hasMore = false
      this.cursor = null
      this.isLoading = false
      this.hydrated = false

      this.load()
    }
  },

  mounted () {
    this.load()
  },

  methods: {
    load () {
      this.isLoading = true
      this.$api.get(this.endpoint, { params: this.cursorQuery })
        .then(response => {
          this.hydrated = true
          if (!response.data || !response.data.meta) {
            // TODO: check if this is aways the case
            this.$bus.$emit('load-more-empty')
            this.isLoading = false
            this.hasMore = false

            return
          }

          this.hasMore = response.data.meta.has_more
          this.cursor = response.data.meta.cursor

          this.list = [...this.list, ...response.data.data]

          // If the resulting list is empty, spread the word.
          if (!this.list.length) {
            this.$bus.$emit('load-more-empty')
          }

          this.isLoading = false
        })
        .catch(error => {
          this.isLoading = false
          // this.$displayRequestError(error, this.$t('error.could_not_fetch_suggested_stories'))
          this.$displayRequestError(error)
        })
    }
  }
}
</script>
